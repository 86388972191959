import ClientSuspense from '@components/ClientSuspense'
import classnames from '@helpers/misc/classnames'
import useNavbarV2 from '@page-components/Layout/Website/Layouts/Default/NavbarV2/hooks/useNavbarV2'
import useCartPreferences from '@page-components/Order/Cart/hooks/useCart.main'

import CartContent from '../CartContent'

import CartComponentSkeleton from './Skeleton'

function InnerCartComponent() {
  const preferences = useCartPreferences()
  const totalItems = preferences?.cart?.items?.length
  const suffix = totalItems > 1 ? 's' : ''
  const isNavbarV2Active = useNavbarV2()

  return (
    <div
      className={classnames(
        'flex flex-col h-[74vh] bg-white dark:bg-zinc-800 rounded-lg ml-1 mt-6 mr-0 px-2 py-4 sticky shadow-[0_0_16px_0_rgba(0,0,0,0.1)]',
        isNavbarV2Active ? 'top-[92px] mt-9' : 'top-5 mt-6'
      )}>
      <span className="px-3 text-lg font-semibold">
        Tu Carrito {totalItems ? `(${totalItems} producto${suffix})` : null}
      </span>
      <CartContent />
    </div>
  )
}

export default function CartComponent() {
  return (
    <ClientSuspense fallback={<CartComponentSkeleton />}>
      <InnerCartComponent />
    </ClientSuspense>
  )
}
