import TextSkeleton from '@components/V3/Text/TextSkeleton'

import CartContentSkeleton from '../CartContent/Skeleton'

export default function CartComponentSkeleton() {
  return (
    <div className="flex flex-col h-[74vh] bg-white dark:bg-zinc-800 rounded-lg ml-1 mt-6 mr-0 px-2 py-4 sticky top-5 shadow-[0_0_16px_0_rgba(0,0,0,0.1)]">
      <span className="px-3 text-lg font-semibold">
        <TextSkeleton type={'title'} />
      </span>
      <CartContentSkeleton />
    </div>
  )
}
