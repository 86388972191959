import CartComponent from './CartComponent'
import CartMobile from './CartMobile'
import CartSidebar from './CartSidebar'

const CartV3 = {
  AsMobile: CartMobile,
  AsComponent: CartComponent,
  AsSidebar: CartSidebar
}

export default CartV3
