import React, {Suspense} from 'react'
import Container from '@packages/justo-parts/lib/components/Container'
import CartV3 from '@page-components/Order/CartV3'
import Loading from '@page-components/Order/Products/ProductsContent/Loading'

export default function LayoutV1({children}: {children: React.ReactNode}) {
  return (
    <Container>
      <div className="row">
        <div className="col-xs-12 col-sm-6 col-md-4 last-sm">
          <CartV3.AsComponent />
        </div>
        <div className="col-xs-12 col-sm-6 col-md-8">
          <Suspense fallback={<Loading fullScreen />}>{children}</Suspense>
        </div>
      </div>
    </Container>
  )
}
