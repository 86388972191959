import ClientSuspense from '@components/ClientSuspense'
import Pill from '@components/V3/Pill'
import FormatNumber from '@i18n/formatNumber/Component'
import Container from '@packages/justo-parts/lib/components/Container'
import useNavbarV2 from '@page-components/Layout/Website/Layouts/Default/NavbarV2/hooks/useNavbarV2'
import useCartPreferences from '@page-components/Order/Cart/hooks/useCart.main'
import OptionsLabel from '@page-components/Order/CartMenuV2/OptionsLabel'
import {useMemo, useState} from 'react'
import {MdShoppingCart} from 'react-icons/md'

import dynamic from 'next/dynamic'
import CartModalContext from '../hooks/useCartModalContext'

const ContentWrapper = dynamic(() => import('./ContentWrapper'), {ssr: false})
const DeliveryWizardModal = dynamic(
  () => import('@components/DeliveryWizard/exportables/DeliveryWizardModal'),
  {ssr: false},
)

function InnerCartSidebar() {
  const preferences = useCartPreferences()
  const {cart} = preferences
  const total = cart?.itemsPrice ?? 0
  const totalItems = useMemo(
    () => (cart?.items || []).reduce((total, item) => total + item.amount, 0),
    [cart?.items],
  )
  const [open, setOpen] = useState<boolean>(false)
  const [openAddress, setOpenAddress] = useState<boolean>(false)
  const navbarV2 = useNavbarV2()
  const closeModal = () => setOpen(false)

  if (navbarV2) {
    return (
      <>
        <div
          className="flex flex-row text-sm orion_primary cursor-pointer items-center p-3.5 rounded-lg w-fit space-x-1"
          onClick={() => {
            if (!preferences?.storeId) {
              setOpenAddress(true)
              return
            }
            setOpen(true)
          }}
        >
          <div className="flex items-center">
            <MdShoppingCart size={18} aria-label="cart" />
          </div>
          <div className="flex justify-center items-center font-bold">
            {totalItems ? `(${totalItems}) ` : '(0)'}
          </div>
        </div>
        <CartModalContext.Provider value={{closeModal: closeModal}}>
          <ContentWrapper open={open} onClose={() => setOpen(false)} />
        </CartModalContext.Provider>
        {openAddress ? (
          <DeliveryWizardModal
            isOpen={openAddress}
            onClose={() => setOpenAddress(false)}
            actionLocation="cart"
          />
        ) : null}
      </>
    )
  }

  return (
    <>
      <div className="fixed sticky top-0 left-0 right-0 z-[300] w-full">
        <div className="primaryBackgroundColor">
          <Container className="flex flex-row justify-between primaryBackgroundColor h-12 items-center">
            <div onClick={() => setOpenAddress(true)}>
              <Pill className="secondaryBackgroundColor primaryColor text-base !px-2 !py-1 !rounded">
                <OptionsLabel />
              </Pill>
            </div>
            <div />
            <div
              className="flex flex-row orion_primary cursor-pointer items-center px-2 py-1 rounded"
              onClick={() => {
                if (!preferences?.storeId) {
                  setOpenAddress(true)
                  return
                }
                setOpen(true)
              }}
            >
              <div className="flex justify-center items-center font-bold">
                {cart ? <FormatNumber value={total} /> : ''}
              </div>
              <div className="flex-1 px-2 text-xs">
                <MdShoppingCart size={18} aria-label="cart" />
              </div>
            </div>
          </Container>
        </div>
      </div>
      <CartModalContext.Provider value={{closeModal: closeModal}}>
        <ContentWrapper open={open} onClose={() => setOpen(false)} />
      </CartModalContext.Provider>
      {openAddress ? (
        <DeliveryWizardModal
          isOpen={openAddress}
          onClose={() => setOpenAddress(false)}
          actionLocation="cart"
        />
      ) : null}
    </>
  )
}

export default function CartSidebar() {
  return (
    <ClientSuspense fallback={null}>
      <InnerCartSidebar />
    </ClientSuspense>
  )
}
