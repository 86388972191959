import React from 'react'
import {MdOutlineWarning} from 'react-icons/md'
import useIsOutOfBounds from '@components/DeliveryWizard/internals/hooks/useIsOutOfBounds'
import usePreferencesForAddressContext from '@components/DeliveryWizard/internals/hooks/usePreferencesForAddressContext.main'
import classnames from '@helpers/misc/classnames'
import {useTranslation} from 'next-i18next'

import styles from './styles.module.css'

export default function OptionsLabel() {
  const {preferences, loading} = usePreferencesForAddressContext()
  const {t} = useTranslation('website', {keyPrefix: 'order.cartMenuV2.optionsLabel'})
  const outOfBounds = useIsOutOfBounds()

  if (!preferences || loading) return <div className={styles.loading}></div>

  if (outOfBounds)
    return (
      <div className={classnames(styles.container, 'flex items-center')}>
        <MdOutlineWarning className="w-5 h-5 mr-2 text-orange-200" />
        <div>{t('outOfBounds')}</div>
      </div>
    )

  if (preferences.deliveryType === 'delivery') {
    if (preferences.address) {
      return <div className={styles.container}>{preferences.address.address}</div>
    } else {
      return <div className={styles.container}>{t('address')}</div>
    }
  }

  if (!preferences.store) {
    return <div className={styles.container}>{t('store')}</div>
  }

  if (preferences.deliveryType === 'go') {
    return <div className={styles.container}>{t('storeGo', {store: preferences.store.name})}</div>
  }

  const label = preferences.tableName
    ? t('labelTable', {table: preferences.tableName})
    : t('labelServe')

  return (
    <div className={styles.container}>
      {preferences.store.name}, {label}
    </div>
  )
}
